import type { FormOption } from '@/types/common/form.type';

export const SCREEN_TYPES = {
  BASIC: 'BASIC',
  VIDEO: 'VIDEO'
} as const;

export const EXPOSURE_TABS = {
  REGION: 'tab-region',
  SEARCH: 'tab-search'
};

export const PRODUCT_PAGE_STATUS = {
  REVIEW: 'review',
  REJECTED: 'rejected',
  ENDED: 'ended',
  SCHEDULED_APPLICATION: 'scheduled_application',
  LIVE_APPLICATION: 'live_application'
};

export const SUPPORTED_FEATURES = {
  SINGLE_PLAYER: 'SINGLE_PLAYER',
  MULTI_PLAYER: 'MULTI_PLAYER',
  PVP: 'PVP',
  COOPERATIVE: 'COOPERATIVE',
  VR_SUPPORT: 'VR_SUPPORT',
  CONTROLLER_SOME_SUPPORT: 'CONTROLLER_SOME_SUPPORT',
  CONTROLLER_PERFECT_SUPPORT: 'CONTROLLER_PERFECT_SUPPORT',
  USER_STAT: 'USER_STAT',
  CHALLENGE: 'CHALLENGE',
  LEADER_BOARD: 'LEADER_BOARD',
  CLOUD_SAVING: 'CLOUD_SAVING',
  MATCH_MAKING: 'MATCH_MAKING',
  INGAME_PAYMENT: 'INGAME_PAYMENT'
} as const;

export const SUPPORTED_FEATURES_LIST = [
  {
    value: SUPPORTED_FEATURES.SINGLE_PLAYER,
    label: 'studio.supported_features.single_player'
  },
  {
    value: SUPPORTED_FEATURES.MULTI_PLAYER,
    label: 'studio.supported_features.multi_player'
  },
  {
    value: SUPPORTED_FEATURES.PVP,
    label: 'studio.supported_features.pvp'
  },
  {
    value: SUPPORTED_FEATURES.COOPERATIVE,
    label: 'studio.supported_features.cooperative'
  },
  {
    value: SUPPORTED_FEATURES.VR_SUPPORT,
    label: 'studio.supported_features.vr_support'
  },
  {
    value: SUPPORTED_FEATURES.CONTROLLER_SOME_SUPPORT,
    label: 'studio.supported_features.controller_some_support'
  },
  {
    value: SUPPORTED_FEATURES.CONTROLLER_PERFECT_SUPPORT,
    label: 'studio.supported_features.controller_perfect_support'
  },
  {
    value: SUPPORTED_FEATURES.CLOUD_SAVING,
    label: 'studio.supported_features.cloud_saving'
  },
  {
    value: SUPPORTED_FEATURES.USER_STAT,
    label: 'studio.supported_features.user_stat'
  },
  {
    value: SUPPORTED_FEATURES.CHALLENGE,
    label: 'studio.supported_features.challenge'
  },
  // {
  //   value: '랭킹',
  //   label: '랭킹'
  // },
  {
    value: SUPPORTED_FEATURES.MATCH_MAKING,
    label: 'studio.supported_features.match_making'
  },
  {
    value: SUPPORTED_FEATURES.LEADER_BOARD,
    label: 'studio.supported_features.leader_board'
  },
  {
    value: SUPPORTED_FEATURES.INGAME_PAYMENT,
    label: 'studio.supported_features.ingame_payment'
  }
];

export const SUPPORTED_LANGUAGES_LIST: FormOption[] = [
  {
    value: 'EN',
    label: 'studio.supported_lang.en'
  },
  {
    value: 'KO',
    label: 'studio.supported_lang.ko'
  },
  {
    value: 'ZH_TW',
    label: 'studio.supported_lang.zh_tw'
  },
  {
    value: 'ZH_CN',
    label: 'studio.supported_lang.zh_cn'
  },
  {
    value: 'JA',
    label: 'studio.supported_lang.ja'
  },
  {
    value: 'DE',
    label: 'studio.supported_lang.ge'
  },
  {
    value: 'FR',
    label: 'studio.supported_lang.fr'
  },
  {
    value: 'ES',
    label: 'studio.supported_lang.es'
  },
  {
    value: 'PT',
    label: 'studio.supported_lang.pt'
  },
  {
    value: 'VI',
    label: 'studio.supported_lang.vi'
  },
  {
    value: 'ID',
    label: 'studio.supported_lang.id'
  },
  {
    value: 'TH',
    label: 'studio.supported_lang.th'
  },
  {
    value: 'IT',
    label: 'studio.supported_lang.it'
  },
  {
    value: 'RU',
    label: 'studio.supported_lang.ru'
  },
  {
    value: 'MS',
    label: 'studio.supported_lang.ms'
  },
  {
    value: 'AR',
    label: 'studio.supported_lang.ar'
  },
  {
    value: 'NL',
    label: 'studio.supported_lang.nl'
  },
  {
    value: 'PL',
    label: 'studio.supported_lang.pl'
  },
  {
    value: 'CS',
    label: 'studio.supported_lang.cs'
  },
  {
    value: 'TR',
    label: 'studio.supported_lang.tr'
  },
  {
    value: 'ETC',
    label: 'studio.supported_lang.etc'
  }
];

export const DISPLAY_STATUS = {
  ON: 'ON',
  OFF: 'OFF'
};

export const LABEL_REQUIRED = ['OS', 'Processor', 'Memory', 'Graphic', 'DirectX', 'Storage'];

export const SETTING_REQUIRED = ['os', 'proccessor', 'memory', 'graphic', 'directx', 'storage'];
